import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  CaseRequestViewModel,
  RequestDocumentViewModel,
  CombinedFilingData,
  ICaseRequestUpdateService,
  ICaseRequestBuilderService,
  FsxCaseRequestBuilderService,
  FsxCaseRequestUpdateService,
} from '@fsx/fsx-shared';
import {
  Subject,
  Observable,
  switchMap,
  concatMap,
  withLatestFrom,
} from 'rxjs';
import {
  FsxValidateDocumentsOrchestrationService,
  IValidateDocumentsOrchestrationService,
} from '../../filing-editor/services/orchestration/validate-documents-orchestration.service';
import {
  FsxCombinedFilingDataService,
  ICombinedFilingDataService,
} from '../../filing-editor/services/combined-filing-data.service';

export const FsxUpdateDocumentOrchestrationService =
  new InjectionToken<IUpdateDocumentOrchestrationService>(
    'FsxUpdateDocumentOrchestrationService'
  );

export interface UpdateDocumentParams {
  requestDocument: RequestDocumentViewModel;
  partialRequestDocument: Partial<RequestDocumentViewModel>;
}

export interface IUpdateDocumentOrchestrationService {
  updateDocumentStream$: Observable<CaseRequestViewModel>;
  updateDocument(params: UpdateDocumentParams): void;
}

@Injectable()
export class UpdateDocumentOrchestrationService
  implements IUpdateDocumentOrchestrationService
{
  private updateDocumentParams$$ = new Subject<UpdateDocumentParams>();
  private updateDocumentParams$ = this.updateDocumentParams$$.asObservable();

  updateDocumentStream$: Observable<CaseRequestViewModel> =
    this.updateDocumentParams$.pipe(
      withLatestFrom(this.combinedFilingDataService.combinedFilingData$),
      concatMap(
        ([updateDocumentParams, combinedFilingData]: [
          UpdateDocumentParams,
          CombinedFilingData
        ]) => {
          const { filing, caseRequest } = combinedFilingData;
          const filingId: string = filing.id;
          const caseRequestBackup = { ...caseRequest } as CaseRequestViewModel;
          return this.caseRequestBuilderService
            .updateRequestDocument({
              caseRequest,
              combinedFilingData,
              ...updateDocumentParams,
            })
            .pipe(
              switchMap(() => {
                this.validateDocumentsOrchestrationService.validateDocuments();
                return this.caseRequestUpdateService.optimisticPutOrRestore(
                  filingId,
                  caseRequest,
                  caseRequestBackup,
                  true
                );
              })
            );
        }
      )
    );

  constructor(
    @Inject(FsxCombinedFilingDataService)
    private readonly combinedFilingDataService: ICombinedFilingDataService,
    @Inject(FsxCaseRequestBuilderService)
    private readonly caseRequestBuilderService: ICaseRequestBuilderService,
    @Inject(FsxValidateDocumentsOrchestrationService)
    private readonly validateDocumentsOrchestrationService: IValidateDocumentsOrchestrationService,
    @Inject(FsxCaseRequestUpdateService)
    private readonly caseRequestUpdateService: ICaseRequestUpdateService
  ) {}

  updateDocument(params: UpdateDocumentParams): void {
    this.updateDocumentParams$$.next(params);
  }
}
