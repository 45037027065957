import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  CasePartyViewModel,
  CombinedFilingData,
  FsxCaseRequestBuilderService,
  FsxCaseRequestUpdateService,
  ICaseRequestBuilderService,
  ICaseRequestUpdateService,
} from '@fsx/fsx-shared';
import { CaseRequestViewModel } from '@fsx/fsx-shared';
import {
  Observable,
  Subject,
  of,
  switchMap,
  tap,
  withLatestFrom,
  zip,
} from 'rxjs';
import {
  FsxValidatePartiesOrchestrationService,
  IValidatePartiesOrchestrationService,
} from '../../filing-editor/services/orchestration/validate-parties-orchestration.service';
import {
  FsxCombinedFilingDataService,
  ICombinedFilingDataService,
} from '../../filing-editor/services/combined-filing-data.service';

export const FsxRemoveParticipantOrchestrationService =
  new InjectionToken<IRemoveParticipantOrchestrationService>(
    'FsxRemoveParticipantOrchestrationService'
  );

export interface IRemoveParticipantEventParams {
  partyToRemove: CasePartyViewModel;
}

export interface IRemoveParticipantOrchestrationService {
  removeParticipantFromCaseRequest$: Observable<CaseRequestViewModel>;
  removeParticipant(params: IRemoveParticipantEventParams): void;
}

@Injectable()
export class RemoveParticipantOrchestrationService
  implements IRemoveParticipantOrchestrationService
{
  private removeParticipant$$ = new Subject<IRemoveParticipantEventParams>();

  removeParticipantFromCaseRequest$: Observable<CaseRequestViewModel> =
    this.removeParticipant$$.pipe(
      withLatestFrom(this.combinedFilingDataService.combinedFilingData$),
      switchMap(
        ([params, combinedFilingData]: [
          IRemoveParticipantEventParams,
          CombinedFilingData
        ]) => {
          const { caseRequest, filing } = combinedFilingData;
          const caseRequestBackup = { ...caseRequest } as CaseRequestViewModel;
          const filingId = filing.id;
          const partyToRemove = params.partyToRemove;
          return zip([
            this.caseRequestBuilderService.removeAssociatedParties({
              filingId,
              caseRequest,
              partyToRemove,
            }),
            this.caseRequestBuilderService.removeAdditionalFieldValues({
              filingId,
              caseRequest,
              partyToRemove,
            }),
          ]).pipe(
            switchMap(() => {
              // Conditionally remove the party and participant based on the existence of the efmKey.
              // (We don't remove existing parties and participants in SubF, but we do remove new ones)
              const conditionallyRemoveParty$: Observable<CaseRequestViewModel> =
                !params.partyToRemove.efmKey
                  ? this.caseRequestBuilderService.removePartyAndParticipant({
                      filingId,
                      caseRequest,
                      partyToRemove,
                    })
                  : of(caseRequest);

              return conditionallyRemoveParty$.pipe(
                switchMap(() => {
                  return this.caseRequestUpdateService
                    .optimisticPutOrRestore(
                      filing.id,
                      caseRequest,
                      caseRequestBackup
                    )
                    .pipe(
                      tap(() => {
                        this.validatePartiesOrchestrationService.validateParties();
                      })
                    );
                })
              );
            })
          );
        }
      )
    );

  constructor(
    @Inject(FsxCombinedFilingDataService)
    private readonly combinedFilingDataService: ICombinedFilingDataService,
    @Inject(FsxValidatePartiesOrchestrationService)
    private readonly validatePartiesOrchestrationService: IValidatePartiesOrchestrationService,
    @Inject(FsxCaseRequestBuilderService)
    private readonly caseRequestBuilderService: ICaseRequestBuilderService,
    @Inject(FsxCaseRequestUpdateService)
    private readonly caseRequestUpdateService: ICaseRequestUpdateService
  ) {}

  removeParticipant(params: IRemoveParticipantEventParams): void {
    this.removeParticipant$$.next(params);
  }
}
