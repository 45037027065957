import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
  ViewChildren,
  QueryList,
  Inject,
} from '@angular/core';
import {
  AdditionalFieldSpec,
  AdditionalFieldValue,
  CasePartyViewModel,
  CaseRequestViewModel,
  CombinedFilingData,
  FILING_SUB_TABS,
  FieldCategory,
  FilingProfile,
  NamedListItem,
  ParticipantFieldDefinition,
  RequestDocumentParticipant,
  RequestParticipantRepresentationViewModel,
  RequestParticipantViewModel,
} from '@fsx/fsx-shared';
import {
  DropdownOption,
  FormControlWithoutModel,
  FsxBasicSingleSelectionComponent,
  ParticipantTableComponent,
  ReferenceResolver,
  SelectionFieldType,
} from '@fsx/ui-components';
import { Moment } from 'moment';
import { Observable } from 'rxjs';
import { DocumentsGridRow } from './participant.model';
import {
  FsxFilingSubTabsService,
  IFilingSubTabsService,
} from 'projects/apps/fsx-ui/src/app/filing-editor/filing-sub-tabs-container/filing-sub-tabs.service';

@Component({
  selector: 'fsx-participant-component',
  templateUrl: './participant.component.html',
  styleUrls: ['./participant.component.scss'],
})
export class FsxParticipantComponent implements OnInit, OnChanges {
  @Input() combinedFilingData!: CombinedFilingData;
  @Input() caption!: string;
  @Input() helpText!: string;
  @Input() hint!: string;
  @Input() documentsGridRow!: DocumentsGridRow;
  @Input() participantFieldDefinition!: ParticipantFieldDefinition;
  @Input() width!: string;
  @Input() validate$!: Observable<Moment | null | undefined>;
  @Input() resolver!: ReferenceResolver;
  @Input() addlFieldSpec!: AdditionalFieldSpec[];
  @Input() initialValues!: RequestDocumentParticipant[] | undefined;
  @Output() selectedParticipantEvent = new EventEmitter<
    RequestDocumentParticipant[]
  >();
  @Output() removeParticipantEvent =
    new EventEmitter<RequestParticipantViewModel>();
  @Output() formControlEmitter = new EventEmitter<FormControlWithoutModel>(
    true
  );

  @ViewChild('selectionComponent')
  selectionComponent!: FsxBasicSingleSelectionComponent;
  @ViewChildren('participantField')
  participantFields!: QueryList<ParticipantTableComponent>;

  /**
   * All dropdown options available for selection.
   */
  private inputDropdownOptions: DropdownOption<void>[] = [];

  /**
   * The dropdown options to display in the single selection component.
   * This is all available dropdown options with any selected values filtered out.
   */
  filteredDropdownOptions: DropdownOption<void>[] = [];

  public fieldType = FieldCategory;
  public selectionType = SelectionFieldType.StringSelectionFieldDefinition;
  public additionalFieldValues: AdditionalFieldValue[] = [];
  public partyDropdownOptions: DropdownOption<void>[] = [];
  public representationDropdownOptions: DropdownOption<void>[] = [];
  public filteredParties: CasePartyViewModel[] = [];
  public representations: RequestParticipantRepresentationViewModel[] = [];
  public filteredRepresentations: RequestParticipantRepresentationViewModel[] =
    [];
  public selectedParticipants: RequestDocumentParticipant[] = [];
  public selectedParticipantsViewModel: RequestParticipantViewModel[] = [];
  minRequired!: number;
  maxAllowed!: number;
  additionalFieldSpecs!: AdditionalFieldSpec[] | null;
  required!: boolean;

  /**
   * @param filingSubTabsService Used to navigate to the parties tab on clicking custom "Add Party" dropdown option.
   */
  constructor(
    @Inject(FsxFilingSubTabsService)
    private readonly filingSubTabsService: IFilingSubTabsService
  ) {}

  ngOnInit(): void {
    this.setInputDropdownOptions(
      this.combinedFilingData.filingProfile,
      this.combinedFilingData.caseRequest
    );
    this.setFilteredDropdownOptions();

    this.minRequired = this.participantFieldDefinition?.minRequired || 0;
    this.maxAllowed = this.participantFieldDefinition?.maxAllowed || 99;
    this.additionalFieldSpecs =
      this.participantFieldDefinition?.additionalFields || null;
    this.required = this.selectedParticipants.length < this.minRequired;
    if (this.initialValues) {
      this.selectedParticipants = this.initialValues;
      this.updateSelectedParticipantsViewModelArray(
        this.selectedParticipants,
        this.selectedParticipantsViewModel
      );
    }
  }

  ngOnChanges(): void {
    this.required = this.selectedParticipants.length < this.minRequired;
    this.setInputDropdownOptions(
      this.combinedFilingData.filingProfile,
      this.combinedFilingData.caseRequest
    );
    this.setFilteredDropdownOptions();
  }

  /**
   * A helper function to set the dropdown options available for selection in the UI.
   *
   * @param filingProfile The filingProfile which we use to lookup the additionalList, which
   * helps us filter out parties and representation.
   *
   * @param caseRequest The caseRequest whcih contains all parties and representation that
   * are candidates to populate the list with.
   */
  private setInputDropdownOptions(
    filingProfile: FilingProfile,
    caseRequest: CaseRequestViewModel
  ) {
    // Lookup the additionalList in the filingProfile.additionalLists array.
    const additionalList = filingProfile.additionalLists.find((list) => {
      return (
        list.name ===
        this.participantFieldDefinition.allowedParticipantCategoriesList
          .additionalListName
      );
    });

    const caseRequestParties = caseRequest.parties || [];

    // Lookup all RequestParticipantRepresentation objects on all CaseParty objects on the CaseRequest.
    this.representations = caseRequestParties
      .flatMap((party: CasePartyViewModel) => {
        const partyRepresentation: RequestParticipantRepresentationViewModel[] =
          party.representation || [];
        return partyRepresentation;
      })
      .reduce(
        (
          acc: RequestParticipantRepresentationViewModel[],
          cur: RequestParticipantRepresentationViewModel
        ) => {
          // And remove duplicates...
          const found = acc.find((rep) => {
            return rep.participantName == cur.participantName;
          });
          if (!found) {
            acc.push(cur);
          }
          return acc;
        },
        []
      );

    // Filter the parties by the looked up additionalList allowed category names
    this.filteredParties = caseRequestParties.filter(
      (party: CasePartyViewModel) => {
        const allowedNames: string[] =
          additionalList?.items.map(
            (namedListItem: NamedListItem) => namedListItem.name
          ) || [];
        const isAllowedCategoryName: boolean = allowedNames.some(
          (name: string) => name === party.participantCategory?.name
        );
        return isAllowedCategoryName;
      }
    );

    // Filter the representation by the looked up additionalList allowed category names
    this.filteredRepresentations = this.representations.filter(
      (rep: RequestParticipantRepresentationViewModel) => {
        const allowedNames: string[] =
          additionalList?.items.map(
            (namedListItem: NamedListItem) => namedListItem.name
          ) || [];
        const isAllowedCategoryName: boolean = allowedNames.some(
          (name: string) => name === rep.participantCategory?.name
        );
        return isAllowedCategoryName;
      }
    );

    // Create the party dropdown options from the filtered parties.
    this.partyDropdownOptions = this.filteredParties.map(
      (party: CasePartyViewModel) => {
        const dropdownOption: DropdownOption<void> = {
          name: party.participantName,
          caption: party.caption,
          selected: false,
        };
        return dropdownOption;
      }
    );

    // Create the representation dropdown options from the filtered representation.
    this.representationDropdownOptions = this.filteredRepresentations?.map(
      (rep: RequestParticipantRepresentationViewModel) => {
        const dropdownOption: DropdownOption<void> = {
          name: rep.participantName,
          caption: rep.caption,
          selected: false,
        };
        return dropdownOption;
      }
    );

    // Combine the party dropdown options with the representation dropdown options.
    this.inputDropdownOptions = [
      ...this.partyDropdownOptions,
      ...this.representationDropdownOptions,
    ];
  }

  private setFilteredDropdownOptions() {
    // Filter out selected dropdown options
    this.filteredDropdownOptions = this.inputDropdownOptions.filter((opt) => {
      const isSelected: boolean = !this.selectedParticipants.some(
        (sp: RequestDocumentParticipant) => sp.participantName === opt.name
      );
      return isSelected;
    });
  }

  /**
   * A handler function for when the user selects a participant from the dropdown list
   */
  onParticipantSelected(
    value: string,
    addlFieldValues: AdditionalFieldValue[]
  ) {
    if (!!value) {
      const rdp: RequestDocumentParticipant = {
        participantName: value,
        additionalFieldValues: addlFieldValues,
      };
      this.updateSelectedParticipantsArray(this.selectedParticipants, rdp);
      this.selectedParticipantsViewModel = [];
      this.updateSelectedParticipantsViewModelArray(
        this.selectedParticipants,
        this.selectedParticipantsViewModel
      );
      this.selectedParticipantEvent.emit(this.selectedParticipants);

      if (this.selectionComponent) {
        this.selectionComponent.clearSelection();
      }
    }
  }

  public updateSelectedParticipantsArray(
    array: RequestDocumentParticipant[],
    element: RequestDocumentParticipant
  ) {
    const i = array.findIndex(
      (_element) => _element.participantName === element.participantName
    );
    if (i > -1) array[i] = element;
    else array.push(element);
  }

  public updateSelectedParticipantsViewModelArray(
    participants: RequestDocumentParticipant[],
    participantsViewModel: RequestParticipantViewModel[]
  ) {
    participants.forEach((participant) => {
      const pvm = this.combinedFilingData.caseRequest.participants?.find(
        (pt) => {
          return participant.participantName === pt.name;
        }
      );
      if (pvm) {
        participantsViewModel.push(pvm);
      }
    });
  }

  clearParticipantEventHandler(
    participantToClear: RequestParticipantViewModel
  ) {
    this.selectedParticipants =
      this.selectedParticipants.filter((rp) => {
        return rp.participantName !== participantToClear.name;
      }) ?? [];
    this.selectedParticipantsViewModel = [];
    if (this.selectedParticipants) {
      this.updateSelectedParticipantsViewModelArray(
        this.selectedParticipants,
        this.selectedParticipantsViewModel
      );
    }
    this.selectedParticipantEvent.emit(this.selectedParticipants);
  }

  setAdditionalFieldValues(
    addlFields: AdditionalFieldValue[],
    participant: RequestParticipantViewModel
  ) {
    this.onParticipantSelected(participant.name, addlFields);
  }

  public validate(): void {
    if (this.selectionComponent) {
      this.selectionComponent.validate();
    }
    if (this.participantFields) {
      this.participantFields.forEach((fld) => fld.validate());
    }
  }

  /**
   * A handler function for when the user clicks on a custom dropdown option link
   * provided in the participant dropdown lists.
   */
  dropdownOptionLinkClickedHandler() {
    this.filingSubTabsService.activateFilingSubTabItem(FILING_SUB_TABS.PARTIES);
  }
}
