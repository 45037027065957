import {
  Component,
  EventEmitter,
  Inject,
  Injector,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, map, Observable, tap } from 'rxjs';
import { FilesUploadedFromAdditionalFieldEventParams } from '../documents/document-form/document-form.component';
import {
  RequestParticipantViewModel,
  RequestParticipantRepresentationViewModel,
  CombinedFilingData,
  TRANSACTION_ID,
  ParticipantCommonCategory,
  FilingMode,
} from '@fsx/fsx-shared';
import { PartiesGridConfig } from './parties-grid/parties-grid.component';
import { PartiesGridRow } from './parties-grid/parties-grid.model';
import {
  FsxPartiesGridConfigService,
  IPartiesGridConfigService,
} from './parties-grid/parties-grid-config.service';

/**
 * An interface to combine the related RequestParticipantRepresentation and
 * RequestParticipant objects into a single type along with the row number
 * on which they reside in the grid.
 */
export interface RepresentationAndParticipant {
  /**
   * A RequestParticipantRepresentation object
   */
  representation: RequestParticipantRepresentationViewModel;

  /**
   * A RequestPartipant object (related to the RequestParticipantRepresentation object above)
   */
  participant: RequestParticipantViewModel;
}

@Component({
  selector: 'fsx-parties',
  templateUrl: './parties.component.html',
  styleUrls: ['./parties.component.scss'],
})
export class PartiesComponent implements OnInit {
  /**
   * The CombinedFilingData object as passed in from the parent container FilingEditorComponent.
   */
  @Input() combinedFilingData$!: Observable<CombinedFilingData>;

  @Input() validationFilteredClass!: string;

  @Output() combinedPartiesGridRowsUpdatedEvent = new EventEmitter<
    PartiesGridRow[]
  >();

  /**
   * An event to raise to the parent container FilingEditorComponent that files were uploaded
   * from an additional field. Since this can occur from any additional field we handle it in
   * the highest container component (which all additional fields can bubble the event up to)
   */
  @Output() filesUploadedFromAdditionalFieldEvent =
    new EventEmitter<FilesUploadedFromAdditionalFieldEventParams>();

  /**
   * The PartiesGridConfig object for the initiating parties grid.
   */
  initiatingGridConfig$: Observable<PartiesGridConfig> =
    this.partiesGridConfigService.getGridVm(
      ParticipantCommonCategory.InitiatingParty
    );

  /**
   * The PartiesGridConfig object for the additional parties grid.
   */
  additionalGridConfig$: Observable<PartiesGridConfig> =
    this.partiesGridConfigService.getGridVm(
      ParticipantCommonCategory.AdditionalParty
    );

  /**
   * The PartiesGridConfig object for the subf case parties grid.
   */
  casePartiesGridConfig$: Observable<PartiesGridConfig> =
    this.partiesGridConfigService.getGridVm();

  /**
   * The current filing id, which gets passed to the orchestration services.
   */
  private filingId = this.route.snapshot.params[TRANSACTION_ID];

  /**
   * An array of PartiesGridRow objects as derived from both the initiating and additional grids.
   * This is emitted upwards and across to the ReviewComponent where it displays a list of all participants on the case.
   * This is also passed down into the PartiesGrid where it's used to exclude participant ids from the contacts search.
   */
  combinedPartiesGridRows$!: Observable<PartiesGridRow[]>;

  protected readonly FilingMode = FilingMode;

  constructor(
    private readonly route: ActivatedRoute,
    @Inject(FsxPartiesGridConfigService)
    private readonly partiesGridConfigService: IPartiesGridConfigService,
    readonly injector: Injector
  ) {}

  ngOnInit(): void {
    // Derive the combined array of PartiesGridRows from the initiating PartiesGridVm's PartiesGridRows
    // and the additional PartiesGridVm's PartiesGridRows.
    this.combinedPartiesGridRows$ = combineLatest([
      this.initiatingGridConfig$,
      this.additionalGridConfig$,
    ]).pipe(
      map(
        ([initiatingGridVm, additionalGridVm]: [
          PartiesGridConfig,
          PartiesGridConfig
        ]) => {
          const initiatingPartiesGridRows: PartiesGridRow[] =
            initiatingGridVm.partiesGridRows;
          const additionalPartiesGridRows: PartiesGridRow[] =
            additionalGridVm.partiesGridRows;
          const combinedPartiesGridRows: PartiesGridRow[] = [
            ...initiatingPartiesGridRows,
            ...additionalPartiesGridRows,
          ];
          return combinedPartiesGridRows;
        }
      ),
      tap((combinedPartiesGridRows: PartiesGridRow[]) => {
        this.combinedPartiesGridRowsUpdatedEvent.emit(combinedPartiesGridRows);
      })
    );
  }

  /**
   * The handler function for the (filesUploadedFromAdditionalFieldEvent) event from
   * each instance of the FsxPartiesGridComponent. This event is raised internally when
   * the user attempts to upload files through an additional field.
   *
   * @param params The document and the uploaded files.
   */
  filesUploadedFromAdditionalFieldEventHandler(
    params: FilesUploadedFromAdditionalFieldEventParams
  ) {
    this.filesUploadedFromAdditionalFieldEvent.emit(params);
  }

  validationFilteredEventHandler(filteredClass: string): void {
    this.validationFilteredClass = filteredClass;
  }
}
