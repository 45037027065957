import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  CasePartyViewModel,
  CaseRequestViewModel,
  ParticipantCategory,
  AdditionalFieldValue,
  ICaseRequestUpdateService,
  FsxCaseRequestUpdateService,
} from '@fsx/fsx-shared';
import { Observable, Subject, switchMap, tap } from 'rxjs';
import {
  FsxCaseRequestDataService,
  ICaseRequestDataService,
} from '../../filing-editor/services/case-request-data.service';

export const FsxUpdateParticipantOrchestrationService =
  new InjectionToken<IUpdateParticipantOrchestrationService>(
    'FsxUpdateParticipantOrchestrationService'
  );

export interface IUpdateParticipantParams {
  filingId: string;
  caseRequest: CaseRequestViewModel;

  /**
   * The index of the party in the caseRequest.parties array. This is used
   * in subsequent PATCH requests to the server when attempting updates.
   */
  caseRequestPartyIndex: number;

  /**
   * The new ParticipantCategory to set.
   */
  participantCategory: ParticipantCategory;

  /**
   * The new AdditionalFieldValues to set.
   */
  additionalFieldValues: AdditionalFieldValue[] | null;
}

export interface IUpdateParticipantOrchestrationService {
  updateParticipantOnCaseRequest$: Observable<CaseRequestViewModel>;
  updateParticipant(params: IUpdateParticipantParams): void;
}

@Injectable()
export class UpdateParticipantOrchestrationService
  implements IUpdateParticipantOrchestrationService
{
  private updateParticipant$$ = new Subject<IUpdateParticipantParams>();

  updateParticipantOnCaseRequest$: Observable<CaseRequestViewModel> =
    this.updateParticipant$$.pipe(
      tap((params: IUpdateParticipantParams) => {
        const caseRequestParties: CasePartyViewModel[] = [
          ...(params.caseRequest.parties || []),
        ];
        const caseParty: CasePartyViewModel =
          caseRequestParties[params.caseRequestPartyIndex];
        caseParty.participantCategory = params.participantCategory;
        params.caseRequest = {
          ...params.caseRequest,
          parties: [...caseRequestParties],
        } as CaseRequestViewModel;

        this.caseRequestDataService.setCaseRequestData(params.caseRequest);
      }),
      switchMap((params: IUpdateParticipantParams) => {
        const caseRequestBackup: CaseRequestViewModel = {
          ...params.caseRequest,
        } as CaseRequestViewModel;
        if (params.additionalFieldValues) {
          return this.caseRequestUpdateService.optimisticPatchAdditionalFieldsOrRestore(
            params.filingId,
            params.caseRequest,
            params.caseRequestPartyIndex,
            params.additionalFieldValues,
            caseRequestBackup
          );
        } else {
          return this.caseRequestUpdateService.optimisticPatchParticipantTypeOrRestore(
            params.filingId,
            params.caseRequest,
            params.caseRequestPartyIndex,
            params.participantCategory,
            caseRequestBackup
          );
        }
      })
    );

  constructor(
    @Inject(FsxCaseRequestUpdateService)
    private readonly caseRequestUpdateService: ICaseRequestUpdateService,
    @Inject(FsxCaseRequestDataService)
    private readonly caseRequestDataService: ICaseRequestDataService
  ) {}

  updateParticipant(params: IUpdateParticipantParams): void {
    this.updateParticipant$$.next(params);
  }
}
