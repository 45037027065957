import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  Subject,
  switchMap,
  mergeMap,
  Observable,
  tap,
  withLatestFrom,
} from 'rxjs';
import {
  CaseRequestViewModel,
  CombinedFilingData,
  FsxCaseRequestBuilderService,
  FsxCaseRequestUpdateService,
  ICaseRequestBuilderService,
  ICaseRequestUpdateService,
  ParticipantCommonCategory,
} from '@fsx/fsx-shared';
import {
  FsxValidatePartiesOrchestrationService,
  IValidatePartiesOrchestrationService,
} from '../../filing-editor/services/orchestration/validate-parties-orchestration.service';
import {
  FsxCombinedFilingDataService,
  ICombinedFilingDataService,
} from '../../filing-editor/services/combined-filing-data.service';
import { v4 as uuidv4 } from 'uuid';

export const FsxAddDefaultParticipantOrchestrationService =
  new InjectionToken<IAddDefaultParticipantOrchestrationService>(
    'FsxAddDefaultParticipantOrchestrationService'
  );

export interface IAddParticipantEventParams {
  participantCommonCategory?: ParticipantCommonCategory;
}

export interface IAddDefaultParticipantOrchestrationService {
  addDefaultParticpantToCaseRequest$: Observable<CaseRequestViewModel>;
  addDefaultParticipant(params: IAddParticipantEventParams): void;
}

@Injectable()
export class AddDefaultParticipantOrchestrationService
  implements IAddDefaultParticipantOrchestrationService
{
  private addDefaultParticipant$$ = new Subject<IAddParticipantEventParams>();

  addDefaultParticpantToCaseRequest$: Observable<CaseRequestViewModel> =
    this.addDefaultParticipant$$.pipe(
      withLatestFrom(this.combinedFilingDataService.combinedFilingData$),
      mergeMap(
        ([params, combinedFilingData]: [
          IAddParticipantEventParams,
          CombinedFilingData
        ]) => {
          const { caseRequest, filing } = combinedFilingData;
          const caseRequestBackup: CaseRequestViewModel = {
            ...caseRequest,
          } as CaseRequestViewModel;
          const uniqueIdentifier = uuidv4();
          return this.caseRequestBuilderService
            .addDefaultPartyAndParticipant(
              uniqueIdentifier,
              caseRequest,
              params.participantCommonCategory
            )
            .pipe(
              switchMap(() => {
                return this.caseRequestUpdateService
                  .optimisticPutOrRestore(
                    filing.id,
                    caseRequest,
                    caseRequestBackup
                  )
                  .pipe(
                    tap(() => {
                      this.validatePartiesOrchestrationService.validateParties();
                    })
                  );
              })
            );
        }
      )
    );

  constructor(
    @Inject(FsxCombinedFilingDataService)
    private readonly combinedFilingDataService: ICombinedFilingDataService,
    @Inject(FsxValidatePartiesOrchestrationService)
    private readonly validatePartiesOrchestrationService: IValidatePartiesOrchestrationService,
    @Inject(FsxCaseRequestBuilderService)
    private readonly caseRequestBuilderService: ICaseRequestBuilderService,
    @Inject(FsxCaseRequestUpdateService)
    private readonly caseRequestUpdateService: ICaseRequestUpdateService
  ) {}

  addDefaultParticipant(params: IAddParticipantEventParams): void {
    this.addDefaultParticipant$$.next(params);
  }
}
