import { Component } from '@angular/core';
import { SidePanelButtonsConfig } from '@fsx/ui-components';

@Component({
  selector: 'fsx-parties-actions',
  templateUrl: './parties-actions.component.html',
  styleUrls: ['./parties-actions.component.scss'],
})
export class PartiesActionsComponent {
  contactActionButtons: SidePanelButtonsConfig[] = [
    {
      buttonCaption: 'Add Party',
      buttonType: 'primary',
      action: () => {
        // do something here
      },
    },
  ];
}
