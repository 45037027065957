<!-- ToDo: Added caption check due to JTI returning null linkedContact on a valid participant -->
<div class="detail-row">
  <div>
    <fsx-row-validation
      *ngIf="row.party.participantCategory"
      [isValid]="!!row.party.participantCategory?.name"
      [cssClass]="'margin-right'"
    >
    </fsx-row-validation>

    <fsx-basic-single-selection
      #partyTypeField
      [id]="i"
      [readOnly]="!!row.party.efmKey"
      [caption]="'Party Type'"
      [helpText]="''"
      [hint]="''"
      [fieldType]="fieldType.Selection"
      [width]="'256px'"
      [selectionType]="selectionType"
      [inputDropdownOptions]="partyTypeDropdownOptions"
      [initialValue]="row.party.participantCategory?.name"
      [clearable]="false"
      [required]="true"
      (formControlEmitter)="setBasicPartyFormControl($event)"
      (selectedValue)="
        onPartyTypeSelected({
          value: $event,
          caseParty: row.party,
          partyIndex: row.partyIndex
        })
      "
    ></fsx-basic-single-selection>
  </div>
  <div
    id="participant-table-wrapper"
    *ngIf="
      row.participant?.linkedContact || !!row.participant.caption;
      else searchContacts
    "
  >
    <fsx-participant-table
      [showIcons]="true"
      [participantsMap]="partiesMap"
      [combinedFilingData]="(combinedFilingData$ | async)!"
      [participant]="row.participant"
      (clearParticipantEvent)="clearParticipantEventHandler(row.party)"
      (editParticipantEvent)="editParticipantEventHandler(row.participant)"
    >
    </fsx-participant-table>
  </div>
  <ng-template #searchContacts>
    <div fxLayout="row" fxLayoutAlign="start center">
      <fsx-contacts-search
        [disabled]="!currentParticipantSpec"
        [excludedContactsIds]="existingPartiesContactIds"
        [searchType]="contactsSearchType.contacts"
        (selectedContactEvent)="
          selectedContactEventHandler($event, row.participant.name)
        "
        (selectedContactSummariesEvent)="
          selectedContactSummariesEventHandler($event)
        "
      >
      </fsx-contacts-search>

      <button
        id="add-{{ isInitiating ? 'initiating' : 'other' }}-party-{{ i }}"
        class="add-party-button"
        [disabled]="!currentParticipantSpec"
        [ngClass]="{ disabled: !currentParticipantSpec }"
        (click)="onAddPartyButtonClicked(row.participant)"
        fxLayout="row"
        fxFlex="nogrow"
        fxLayoutAlign="start center"
      >
        <span class="material-icons add-icon"> add_circle_outline </span>
        Add Party
      </button>

      <button
        class="open-contacts-button"
        fxLayout="row"
        fxFlex="nogrow"
        fxLayoutAlign="start center"
      >
        <span
          id="open-contacts-icon"
          (click)="onOpenContactsIconClicked(row.participant.name)"
        >
        </span>
      </button>
    </div>
  </ng-template>

  <!-- TODO: Uncomment and fix console errors
  <fsx-subcategory-component 
    *ngIf="currentParticipantSpec && currentParticipantSpec.subCategory" 
    [width]="'300px'"
    [fieldDefinition]="currentParticipantSpec.subCategory" 
    [initialValues]="row.party.participantSubCategoryNames??[]"
    [resolver]="resolver" 
    (setValues)="setValues($event, row.partyIndex)">
  </fsx-subcategory-component> -->

  <fsx-representation-grid
    *ngIf="row.participant?.person || row.participant?.organization"
    [repGridConfig]="{
      partyGridRow: row,
      participantSpecs: partiesGridConfig.participantSpecs,
      attorneySpecs: partiesGridConfig.attorneySpecs
    }"
    [resolver]="resolver"
    [combinedFilingData]="(combinedFilingData$ | async)!"
    [participantsMap]="partiesMap"
    (attorneySelectedEvent)="attorneySelectedEventHandler($event)"
    (removeRepresentationEvent)="removeRepresentationEventHandler($event)"
    (updateRepresentationEvent)="updateRepresentationEventHandler($event)"
    (clearRepresentationEvent)="clearRepresentationEventHandler($event)"
    (contactSummariesSelectedEvent)="
      contactSummariesSelectedEventHandler($event)
    "
    (editRepresentationEvent)="editRepresentationEventHandler($event)"
    (addRepresentationEvent)="addRepresentationEventHandler($event)"
    (validateParentPartyEvent)="validateParticipant($event)"
  >
  </fsx-representation-grid>
  <div class="additional-fields-container">
    <fsx-additional-fields-component
      #additionalFields
      *ngFor="
        let addlFieldSpec of partiesGridConfig.participantSpecs[i]
          ?.additionalFields;
        let index = index
      "
      [id]="i"
      [additionalFieldSpec]="addlFieldSpec"
      [combinedFilingData]="(combinedFilingData$ | async)!"
      [initialValues]="
        resolver.getAdditionalFieldInitialValue(
          addlFieldSpec,
          additionalFieldValues[row.partyIndex]
        )
      "
      [existingAdditionalValues]="additionalFieldValues[row.partyIndex]"
      [resolver]="resolver"
      [fileUploadDocumentInfos]="partyFileUploadDocumentInfos"
      (additionalFieldsValuesEmitter)="
        setAdditionalFieldValues($event, row.partyIndex)
      "
      (filesUploadedFromAdditionalFieldEvent)="
        filesUploadedFromPartiesAdditionalFieldEventHandler($event, row)
      "
    >
    </fsx-additional-fields-component>
  </div>
</div>
