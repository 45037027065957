import { Component, Inject, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
  AddressFormService,
  AliasFormService,
  CreateParticipantService,
  EmailFormService,
  FsxAddressFormService,
  FsxAliasFormService,
  FsxContact,
  FsxCreateParticipantService,
  FsxEmailFormService,
  FsxIdentificationsFormService,
  FsxPartiesFormService,
  FsxPersonFormService,
  FsxPhoneFormService,
  IdentificationsFormService,
  PartiesFormService,
  PersonFormService,
  PhoneFormService,
} from '@fsx/fsx-shared';
import { Observable, Subject, tap, takeUntil, combineLatest } from 'rxjs';
import { MatIconRegistry } from '@angular/material/icon';
import { AuthService } from '@fsx/fsx-shared';
import {
  FsxNewFilingOrchestrationService,
  INewFilingOrchestrationService,
} from './transactions/services/orchestration/new-filing-orchestration.service';
import {
  FsxOpenFilingOrchestrationService,
  IOpenFilingOrchestrationService,
} from './transactions/services/orchestration/open-filing-orchestration.service';
import {
  FsxIsLoadingStateService,
  IIsLoadingStateService,
} from './transactions/services/ui-state/is-loading-state.service';
import {
  FsxEditParticipantOrchestrationService,
  EditParticipantOrchestrationService,
} from './parties/orchestration-services/edit-participant-orchestration.service';
import {
  FsxContactProfileDataService,
  ContactProfileDataService,
} from './filing-editor/services/contact-profile-data.service';

@Component({
  selector: 'fsx-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    {
      provide: FsxEditParticipantOrchestrationService,
      useClass: EditParticipantOrchestrationService,
    },
    {
      provide: FsxCreateParticipantService,
      useClass: CreateParticipantService,
    },
    {
      provide: FsxPartiesFormService,
      useClass: PartiesFormService,
    },
    {
      provide: FsxPersonFormService,
      useClass: PersonFormService,
    },
    {
      provide: FsxAliasFormService,
      useClass: AliasFormService,
    },
    {
      provide: FsxAddressFormService,
      useClass: AddressFormService,
    },
    {
      provide: FsxPhoneFormService,
      useClass: PhoneFormService,
    },
    {
      provide: FsxEmailFormService,
      useClass: EmailFormService,
    },
    {
      provide: FsxIdentificationsFormService,
      useClass: IdentificationsFormService,
    },
    {
      provide: FsxContactProfileDataService,
      useClass: ContactProfileDataService,
    },
  ],
})
export class AppComponent implements OnDestroy {
  public user$: Observable<FsxContact | null> = this.authService.sessionUser;
  private destroy$: Subject<unknown> = new Subject();
  public isLoading$!: Observable<boolean>;

  private isAuthenticated$: Observable<boolean> =
    this.authService.isAuthenticated.pipe(
      tap((isAuthenticated: boolean) => {
        if (isAuthenticated) {
          this.authService.stopSessionChecks();
        }
      })
    );

  private globalActionStreams$ = combineLatest([
    this.newFilingOrchestrationService.createNewFilingAndOpenInFilingEditor$,
    this.openFilingOrchestrationService.openFilingInFilingEditor$,
  ]);

  public constructor(
    private readonly authService: AuthService,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
    @Inject(FsxNewFilingOrchestrationService)
    private readonly newFilingOrchestrationService: INewFilingOrchestrationService,
    @Inject(FsxOpenFilingOrchestrationService)
    private readonly openFilingOrchestrationService: IOpenFilingOrchestrationService,
    @Inject(FsxIsLoadingStateService)
    private readonly isLoadingStateService: IIsLoadingStateService
  ) {
    this.matIconRegistry.addSvgIconSet(
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/fsx-svg-icons.svg'
      ),
      { withCredentials: true }
    );
    this.isAuthenticated$.pipe(takeUntil(this.destroy$)).subscribe();

    this.globalActionStreams$.pipe(takeUntil(this.destroy$)).subscribe();

    this.isLoading$ = this.isLoadingStateService.isLoading$;
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
