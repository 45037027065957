import { Injectable, InjectionToken } from '@angular/core';
import {
  CaseRequestViewModel,
  DEFAULT_PARTY,
  ParticipantCategory,
  CasePartyViewModel,
  ParticipantCommonCategory,
} from '@fsx/fsx-shared';
import { map, Observable, of } from 'rxjs';

/**
 * The InjectionToken to use in the providers array to specify a concrete-implementation
 * of the IDefaultPartyService to use at runtime.
 */
export const FsxDefaultPartyService = new InjectionToken<IDefaultPartyService>(
  'FsxDefaultPartyService'
);

/**
 * A blueprint of a worker service with methods for adding and resetting the
 * default CaseParty object on a CaseRequest object in observable stream.
 */
export interface IDefaultPartyService {
  /**
   * A method for adding a default CaseParty object to a CaseRequest object.
   *
   * @param caseRequest The CaseRequest object to add the default CaseParty object to.
   * @param participantName The unique identifier to apply to the default CaseParty object.
   * @param participantCategory The participant category to apply to the default CaseParty object.
   */
  addDefaultParty(
    caseRequest: CaseRequestViewModel,
    participantName: string,
    participantCommonCategory?: ParticipantCommonCategory
  ): Observable<CaseRequestViewModel>;

  /**
   * A method for resetting a CaseParty object back to a default caseParty object on a CaseRequest object.
   *
   * @param caseRequest The CaseRequest object which contains the CaseParty object to reset.
   *
   * @param participantName The unique identifier to identify the CaseParty object in the
   * CaseRequest.parties collection.
   *
   * @param participantCategory The participant category to apply to the default CaseParty object.
   */
  setAsDefaultParty(
    caseRequest: CaseRequestViewModel,
    participantName: string,
    participantCommonCategory?: ParticipantCommonCategory
  ): Observable<CaseRequestViewModel>;
}

@Injectable()
export class DefaultPartyService implements IDefaultPartyService {
  /**
   * A private helper method for setting a default caseParty object.
   *
   * @param participantName The unique identifier to apply to the default CaseParty object.
   * @param participantCategory The participant category to apply to the default CaseParty object.
   * @returns The CaseParty object as an Observable.
   */
  private getDefaultParty(
    participantName: string,
    caseId: string,
    participantCommonCategory?: ParticipantCommonCategory
  ): Observable<CasePartyViewModel> {
    return of({
      ...DEFAULT_PARTY,
      participantName: participantName,
      participantCategory: {
        name: '',
        caption: '',
        commonCategory: participantCommonCategory,
      },
      caseId,
    } as CasePartyViewModel);
  }

  /**
   * A method for adding a default CaseParty object to a CaseRequest object.
   *
   * @param caseRequest The CaseRequest object to add the default CaseParty object to.
   * @param participantName The unique identifier to apply to the default CaseParty object.
   * @param participantCategory The participant category to apply to the default CaseParty object.
   */
  addDefaultParty(
    caseRequest: CaseRequestViewModel,
    participantName: string,
    participantCommonCategory?: ParticipantCommonCategory
  ): Observable<CaseRequestViewModel> {
    return this.getDefaultParty(
      participantName,
      caseRequest.cases![0].caseId!,
      participantCommonCategory
    ).pipe(
      map((defaultParty: CasePartyViewModel) => {
        const caseRequestParties: CasePartyViewModel[] =
          caseRequest.parties || [];
        caseRequestParties.push(defaultParty);
        caseRequest.parties = caseRequestParties;
        return caseRequest;
      })
    );
  }

  /**
   * A method for resetting a CaseParty object back to a default caseParty object on a CaseRequest object.
   *
   * @param caseRequest The CaseRequest object which contains the CaseParty object to reset.
   *
   * @param participantName The unique identifier to identify the CaseParty object in the
   * CaseRequest.parties collection.
   *
   * @param participantCategory The participant category to apply to the default CaseParty object.
   */
  setAsDefaultParty(
    caseRequest: CaseRequestViewModel,
    participantName: string,
    participantCommonCategory?: ParticipantCommonCategory
  ): Observable<CaseRequestViewModel> {
    return this.getDefaultParty(
      participantName,
      caseRequest.cases![0].caseId!,
      participantCommonCategory
    ).pipe(
      map((defaultParticipant: CasePartyViewModel) => {
        const caseRequestParties: CasePartyViewModel[] =
          caseRequest.parties || [];
        caseRequest.parties = caseRequestParties.map((p, i) => {
          return p.participantName === participantName ? defaultParticipant : p;
        });
        return caseRequest;
      })
    );
  }
}
