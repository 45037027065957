import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  CasePartyViewModel,
  CaseRequestViewModel,
  ContactViewModel,
  ContactSummaryViewModel,
  CreateContactsService,
  FilingProfile,
  ParticipantCategory,
  ParticipantSpec,
  ICaseRequestUpdateService,
  ICaseRequestBuilderService,
  FsxCaseRequestBuilderService,
  FsxCaseRequestUpdateService,
} from '@fsx/fsx-shared';
import { from, Observable, Subject, switchMap } from 'rxjs';

export const FsxAddSelectedContactsAsRepresentationOrchestrationService =
  new InjectionToken<IAddSelectedContactsAsRepresentationOrchestrationService>(
    'FsxAddSelectedContactsAsRepresentationOrchestrationService'
  );

export interface IAddSelectedContactsAsRepresentativesParams {
  filingId: string;
  caseRequest: CaseRequestViewModel;
  contactSummaries: ContactSummaryViewModel[];
  participantCategory: ParticipantCategory;
  participantSpec: ParticipantSpec;
  filingProfile: FilingProfile;
  partyToAddTo: CasePartyViewModel;
}

export interface IAddSelectedContactsAsRepresentationOrchestrationService {
  addContactsAsRepresentativesStream$: Observable<CaseRequestViewModel>;
  addSelectedContactsAsRepresentatives(
    params: IAddSelectedContactsAsRepresentativesParams
  ): void;
}

@Injectable()
export class AddSelectedContactsAsRepresentationOrchestrationService
  implements IAddSelectedContactsAsRepresentationOrchestrationService
{
  private addSelectedContactsAsRepresentatives$$ =
    new Subject<IAddSelectedContactsAsRepresentativesParams>();

  addContactsAsRepresentativesStream$: Observable<CaseRequestViewModel> =
    this.addSelectedContactsAsRepresentatives$$.pipe(
      switchMap((params: IAddSelectedContactsAsRepresentativesParams) => {
        const caseRequestBackup: CaseRequestViewModel = {
          ...params.caseRequest,
        } as CaseRequestViewModel;
        return this.createContactsService
          .createContactsFromContactSummaries(params.contactSummaries)
          .pipe(
            switchMap((contacts: ContactViewModel[]) => {
              return from(contacts).pipe(
                switchMap((contact: ContactViewModel) => {
                  // return this.caseRequestBuilderService.createRepresentationAndParticipantThenSetInCaseRequest({
                  //   ...params,
                  //   contact
                  // }).pipe(
                  //   switchMap(() => {
                  return this.caseRequestUpdateService.optimisticPutOrRestore(
                    params.filingId,
                    params.caseRequest,
                    caseRequestBackup
                  );
                  //   })
                  // );
                })
              );
            })
          );
      })
    );

  constructor(
    private readonly createContactsService: CreateContactsService,
    @Inject(FsxCaseRequestBuilderService)
    private readonly caseRequestBuilderService: ICaseRequestBuilderService,
    @Inject(FsxCaseRequestUpdateService)
    private readonly caseRequestUpdateService: ICaseRequestUpdateService
  ) {}

  addSelectedContactsAsRepresentatives(
    params: IAddSelectedContactsAsRepresentativesParams
  ): void {
    this.addSelectedContactsAsRepresentatives$$.next(params);
  }
}
