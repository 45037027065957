<div class="add-associated-party-row" fxLayout="column">
  <div *ngFor="let participant of selectedParticipants">
    <fsx-participant-table
      #participantsField
      *ngIf="participant"
      [combinedFilingData]="combinedFilingData"
      [participant]="participant"
      [isAssociatedParty]="true"
      [resolver]="resolver"
      [showIcons]="true"
      [additionalFieldSpec]="
        participantFieldSpec.participantFieldDefinition.additionalFields
      "
      [additionalFieldValues]="additionalFieldValues"
      [fileUploadDocumentInfos]="fileUploadDocumentInfos"
      (clearParticipantEvent)="clearParticipantEventHandler(participant)"
      (setAdditionalFieldsEvent)="setAdditionalFieldValues($event)"
      (filesUploadedFromAdditionalFieldEvent)="
        filesUploadedFromAdditionalFieldEventHandler($event)
      "
      (fileRemovedFromAdditionalFieldEvent)="
        fileRemovedFromAdditionalFieldEventHandler($event)
      "
    >
    </fsx-participant-table>
  </div>
  <fsx-basic-single-selection
    *ngIf="selectedParticipants.length < maxAllowed; else maxAllowedReached"
    [caption]="caption"
    [helpText]="''"
    [hint]="''"
    [fieldType]="fieldType.Selection"
    [width]="!!width ? width : '162px'"
    [selectionType]="selectionType"
    [inputDropdownOptions]="filteredDropdownOptions"
    [autoSelectSingleOption]="false"
    [required]="required"
    [dropdownOptionLink]="'Add Party'"
    (dropdownOptionLinkClicked)="dropdownOptionLinkClickedHandler()"
    (formControlEmitter)="participantformControl($event)"
    (selectedValue)="
      onParticipantSelected({
        value: $event,
        requestDocument: documentsGridRow.requestDocument,
        documentIndex: documentsGridRow.rowIndex
      })
    "
  >
  </fsx-basic-single-selection>
  <ng-template #maxAllowedReached>
    <p>
      The maximum number of associated parties ({{ maxAllowed }}) has been
      reached
    </p>
  </ng-template>
</div>
