import { Injectable, InjectionToken } from '@angular/core';
import { ContactProfile } from '@fsx/fsx-shared';
import { BehaviorSubject, Observable, filter, map } from 'rxjs';

/**
 * The InjectionToken to use in the providers array to specify a concrete-implementation
 * of the IContactProfileDataService to use at runtime.
 */
export const FsxContactProfileDataService =
  new InjectionToken<IContactProfileDataService>(
    'FsxContactProfileDataService'
  );

/**
 * A blueprint for a state service, which stores a ContactProfile object.
 */
export interface IContactProfileDataService {
  /**
   * A public member, which exposes the ContactProfile object as an Observable
   */
  contactProfile$: Observable<ContactProfile>;

  /**
   * A public method, which sets the ContactProfile object
   */
  setContactProfileData(caseRequest: ContactProfile): void;
}

/**
 * A concrete implementation of a state service, which stores a ContactProfile object.
 */
@Injectable()
export class ContactProfileDataService implements IContactProfileDataService {
  /**
   * A private member, which stores the ContactProfile object in a BehaviorSubject
   */
  private contactProfile$$ = new BehaviorSubject<ContactProfile | null>(null);

  /**
   * A public member, which exposes the ContactProfile object as an Observable
   */
  contactProfile$: Observable<ContactProfile> = this.contactProfile$$
    .asObservable()
    .pipe(
      filter((caseRequest) => caseRequest !== null),
      map((caseRequest) => caseRequest as ContactProfile)
    );

  /**
   * A public method, which sets the ContactProfile object
   *
   * @param contactProfile The ContactProfile object to store
   */
  setContactProfileData(contactProfile: ContactProfile): void {
    const newContactProfileObj = JSON.parse(JSON.stringify(contactProfile));
    this.contactProfile$$.next(newContactProfileObj);
  }
}
