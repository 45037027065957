import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import {
  CasePartyViewModel,
  CombinedFilingData,
  ParticipantSpec,
} from '@fsx/fsx-shared';
import {
  AddParticipantEventParams,
  ParticipantViewMinMaxValues,
} from '../parties-grid/parties-grid.component';
import { PartiesGridRow } from '../parties-grid/parties-grid.model';
import { FsxReferenceResolver } from '../../shared/resolvers/list-reference.resolver';
import {
  FsxParticipantValidationService,
  IParticipantValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/participant-validation.service';
import {
  FsxPartyValidationService,
  IPartyValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/party-validation.service';
import {
  FsxCombinedFilingDataService,
  ICombinedFilingDataService,
} from '../../filing-editor/services/combined-filing-data.service';
import { take, tap } from 'rxjs';
import {
  FsxAddDefaultParticipantOrchestrationService,
  IAddDefaultParticipantOrchestrationService,
} from '../orchestration-services/add-default-participant-orchestration.service';
import {
  FsxRemoveParticipantOrchestrationService,
  IRemoveParticipantOrchestrationService,
} from '../orchestration-services/remove-participant-orchestration.service';

export interface SubsequentPartiesGridConfig {
  combinedFilingData: CombinedFilingData;
  partiesGridRows: PartiesGridRow[];
  participantSpecs: ParticipantSpec[];
  attorneySpecs: ParticipantSpec[];
}

@Component({
  selector: 'fsx-subf-parties-grid',
  templateUrl: './subf-parties-grid.component.html',
  styleUrls: ['./subf-parties-grid.component.scss'],
})
export class SubfPartiesGridComponent implements OnChanges, OnInit {
  /**
   * The config object for the subf parties grid.
   */
  @Input() config!: SubsequentPartiesGridConfig;

  @Input() attorneySpecs!: ParticipantSpec[];

  @Output() addParticipantEvent = new EventEmitter<AddParticipantEventParams>();

  @Output() removeParticipantEvent = new EventEmitter<CasePartyViewModel>();

  public existingPartiesContactIds: string[] = [];

  public currentParticipantSpec: ParticipantSpec | undefined;

  public resolver!: FsxReferenceResolver;

  public partiesMap = new Map<string, ParticipantViewMinMaxValues>();

  public expandedRowIndex: number | null = null;

  public isMaxAllowed: boolean = false;

  constructor(
    @Inject(FsxCombinedFilingDataService)
    private readonly combinedFilingDataService: ICombinedFilingDataService,
    @Inject(FsxParticipantValidationService)
    private readonly participantValidationService: IParticipantValidationService,
    @Inject(FsxPartyValidationService)
    private readonly partyValidationService: IPartyValidationService,
    @Inject(FsxAddDefaultParticipantOrchestrationService)
    private readonly addDefaultParticipantOrchestrationService: IAddDefaultParticipantOrchestrationService,
    @Inject(FsxRemoveParticipantOrchestrationService)
    private readonly removeParticipantOrchestrationService: IRemoveParticipantOrchestrationService
  ) {}

  ngOnInit(): void {
    this.currentParticipantSpec = this.config.participantSpecs[0];
  }

  ngOnChanges(): void {
    this.setParticipantsMap();
  }

  private setParticipantsMap(): void {
    this.config.partiesGridRows?.forEach((partyGridRow) => {
      const spec = this.config.participantSpecs.find(
        (spec) =>
          spec.participantCategory.name ===
          partyGridRow.party.participantCategory?.name
      );
      if (!!spec) {
        const partyVals: ParticipantViewMinMaxValues = {
          isReadOnly: !!partyGridRow.party.efmKey,
          minRequired: spec?.minRequired as number,
          maxAllowed: spec?.maxAllowed as number,
        };
        this.partiesMap.set(partyGridRow.participant.name, partyVals);

        partyGridRow.representationGridRows.forEach((representationGridRow) => {
          const repVals: ParticipantViewMinMaxValues = {
            isReadOnly: !!representationGridRow.representation.efmKey,
            minRequired: spec.representation?.minRequired as number,
            maxAllowed: spec.representation?.maxAllowed as number,
          };
          this.partiesMap.set(
            representationGridRow.representation.participantName,
            repVals
          );
        });
      }
    });
  }

  onToggleExpandDetailRow(event: Event, index: number, _row: PartiesGridRow) {
    event.stopPropagation();
    this.expandedRowIndex = this.expandedRowIndex !== index ? index : null;
    // this.validateParticipant(row);
    // this.validateFormFields(index);
  }

  private optimisticallySetExpandedRowIndex() {
    const newRowIndex = this.config.partiesGridRows
      ? this.config.partiesGridRows.length
      : 0;
    this.expandedRowIndex = newRowIndex;
  }

  /**
   * A method to trigger validation of the participant (RequestParticipant) and party (CaseParty)
   * on the caseRequest.
   *
   * @param row The PartiesGridRow containing the participant and party to which the validation should be run against.
   * z
   */
  validateParticipant(row: PartiesGridRow): void {
    this.combinedFilingDataService.combinedFilingData$.pipe(
      take(1),
      tap((combinedFilingData: CombinedFilingData) => {
        this.participantValidationService.validateParticipant(
          row.participant,
          [this.currentParticipantSpec!],
          combinedFilingData.caseRequest, // Passed in once for the scope (not always guarenteed to be caseRequest, but is in this instance)
          combinedFilingData.filingProfile,
          combinedFilingData.caseRequest // Passed in again for the caseRequest proper, used in dependent services
        );

        this.partyValidationService.validateParty(
          row.party,
          this.currentParticipantSpec!,
          combinedFilingData.caseRequest,
          combinedFilingData.filingProfile,
          combinedFilingData.modeSpec
        );
      })
    );
  }

  onAddParticipantClicked() {
    this.optimisticallySetExpandedRowIndex();
    this.addDefaultParticipantOrchestrationService.addDefaultParticipant({});
  }

  /**
   * The handler function for when the user clicks the trash can icon
   * on a participant row in the grid.
   *
   * @param event The remove button's click event.
   * @param row The row containing the case party to remove.
   */
  onRemoveParticipantClicked(event: Event, row: PartiesGridRow) {
    event.stopPropagation();
    this.removeParticipantOrchestrationService.removeParticipant({
      partyToRemove: row.party,
    });
  }
}
