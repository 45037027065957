import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ContactViewModel,
  CasePartyViewModel,
  DEFAULT_PARTY,
  DEFAULT_PARTICIPANT,
  ParticipantSpec,
  RequestParticipantRepresentationViewModel,
  ParticipantCategory,
  StringSelectionFieldDefinition,
  ContactSummaryViewModel,
  RequestParticipantViewModel,
  AdditionalFieldValue,
  CombinedFilingData,
  FilingMode,
  IValidationService,
  FsxValidationService,
} from '@fsx/fsx-shared';
import {
  FormControlWithModel,
  RepresentationGridRow,
} from '@fsx/ui-components';
import { debounceTime, Subject, takeUntil, tap } from 'rxjs';
import { ContactsSearchComponent } from '../../contacts/contacts-search/contacts-search.component';
import { ContactsSearchTypeEnum } from '../../contacts/contacts.model';
import { PartiesGridRow } from '../parties-grid/parties-grid.model';
import {
  RemoveRepresentationEventParams,
  UpdateRepresentationEventParams,
} from '../representation-grid-item/representation-grid-item.component';
import { FsxReferenceResolver } from '../../shared/resolvers/list-reference.resolver';
import { v4 as uuidv4 } from 'uuid';
import {
  EditRepresentationEventParams,
  ParticipantViewMinMaxValues,
} from '../parties-grid/parties-grid.component';
import { PartyAndParticipant } from '../../filing-editor/services/party-and-participant-data.service';

/**
 * An interface defining the config object for the representation grid.
 */
export interface RepresentationGridConfig {
  partyGridRow: PartiesGridRow;

  participantSpecs: ParticipantSpec[];

  /**
   * The array of PartiicipantSpec objects which we need to derive the "Role" dropdown options
   * on each representation grid row.
   */
  attorneySpecs: ParticipantSpec[];
}

export interface ParticipantSelectedEventParams {
  participantSpec: ParticipantSpec;
  partyToAddTo: CasePartyViewModel;
}

export interface AttorneySelectedEventParams
  extends ParticipantSelectedEventParams {
  contact: ContactViewModel;
  participantSpec: ParticipantSpec;

  /**
   * The CaseParty and RequestParticipant objects for the party that the
   * representation is to be added to.
   */
  partyAndParticipant: PartyAndParticipant;
}

export interface ContactSummariesSelectedEventParams {
  contactSummaries: ContactSummaryViewModel[];
  participantSpec: ParticipantSpec;
  partyToAddTo: CasePartyViewModel;
}

@Component({
  selector: 'fsx-representation-grid',
  templateUrl: './representation-grid.component.html',
  styleUrls: ['./representation-grid.component.scss'],
})
export class RepresentationGridComponent implements OnInit, OnChanges {
  @ViewChild('repSearch') repSearch!: ContactsSearchComponent;

  /**
   * The config object for the representation grid.
   */
  @Input() repGridConfig!: RepresentationGridConfig;
  @Input() resolver!: FsxReferenceResolver;
  @Input() combinedFilingData!: CombinedFilingData | null;
  @Input() participantsMap!: Map<string, ParticipantViewMinMaxValues>;

  @Output() attorneySelectedEvent =
    new EventEmitter<AttorneySelectedEventParams>();
  @Output() contactSummariesSelectedEvent =
    new EventEmitter<ContactSummariesSelectedEventParams>();
  @Output() removeRepresentationEvent =
    new EventEmitter<RemoveRepresentationEventParams>();
  @Output() updateRepresentationEvent =
    new EventEmitter<UpdateRepresentationEventParams>();
  @Output() clearRepresentationEvent = new EventEmitter<CasePartyViewModel>();
  @Output() editRepresentationEvent =
    new EventEmitter<EditRepresentationEventParams>();
  @Output() addRepresentationEvent =
    new EventEmitter<ParticipantSelectedEventParams>();

  /**
   *
   */
  @Output() validateParentPartyEvent = new EventEmitter<PartiesGridRow>();

  public componentInstanceId = uuidv4();
  public contactsSearchType = ContactsSearchTypeEnum;
  public expandedRowIndex: number | null = null;
  public participantsListFormControl!: FormControlWithModel<StringSelectionFieldDefinition>;
  public optimisticUpdates = true;
  public allowNewRepresentation: boolean = true;

  private attorneySelected$$ = new Subject<ContactViewModel>();
  private destroy$$ = new Subject<void>();

  public readonly FilingMode = FilingMode;

  constructor(
    @Inject(FsxValidationService)
    private readonly validationService: IValidationService
  ) {}

  ngOnInit(): void {
    this.setupSelectedAttorneySubscription();
    this.setAllowNewRepresentation();
  }

  ngOnChanges(): void {
    this.setAllowNewRepresentation();
  }

  onSelfRepresentedClicked(caseParty: CasePartyViewModel) {
    ``;
    caseParty.isSelfRepresented = !caseParty.isSelfRepresented;
    if (caseParty.isSelfRepresented) {
      this.optimisticallyClearRepresentation();
      this.clearRepresentationEvent.emit(caseParty);
    }
    this.validateParentPartyEvent.emit(this.repGridConfig.partyGridRow);
  }

  private optimisticallyClearRepresentation() {
    if (this.optimisticUpdates) {
      this.repGridConfig.partyGridRow.representationGridRows = [];
    }
  }

  selectedAttorneyEventHandler(contact: ContactViewModel) {
    const attorneyToAdd =
      this.repGridConfig.partyGridRow.representationGridRows.find(
        (representation) =>
          representation.participant.linkedContact?.id === contact.id
      );

    if (!attorneyToAdd) {
      this.attorneySelected$$.next(contact);
      this.repSearch.clearInput();
      // this.validate();
    }
  }

  selectedContactSummariesEventHandler(
    contactSummaries: ContactSummaryViewModel[]
  ) {
    this.contactSummariesSelectedEvent.emit({
      contactSummaries,
      partyToAddTo: this.repGridConfig.partyGridRow.party,
      participantSpec: this.repGridConfig.attorneySpecs[0],
    });
    this.validateParentPartyEvent.emit(this.repGridConfig.partyGridRow);
  }

  private setupSelectedAttorneySubscription() {
    this.attorneySelected$$
      .pipe(
        debounceTime(500),
        tap((contact: ContactViewModel) => {
          this.optimisticallySetRepresentationFromContact(contact);

          this.attorneySelectedEvent.next({
            contact,
            partyAndParticipant: {
              party: this.repGridConfig.partyGridRow.party,
              participant: this.repGridConfig.partyGridRow.participant,
              partyIndex: this.repGridConfig.partyGridRow.partyIndex,
            },
            partyToAddTo: this.repGridConfig.partyGridRow.party,
            participantSpec: this.repGridConfig.attorneySpecs[0],
          });
        }),
        takeUntil(this.destroy$$)
      )
      .subscribe();
  }

  private optimisticallySetRepresentationFromContact(
    contact: ContactViewModel
  ) {
    if (this.optimisticUpdates) {
      this.repGridConfig.partyGridRow.representationGridRows = [
        ...this.repGridConfig.partyGridRow.representationGridRows,
        new RepresentationGridRow(
          this.repGridConfig.partyGridRow.representationGridRows.length,
          {
            ...DEFAULT_PARTY,
            caption: contact.caption,
            participantCategory:
              this.repGridConfig.attorneySpecs[0].participantCategory,
          } as unknown as RequestParticipantRepresentationViewModel,
          {
            ...DEFAULT_PARTICIPANT,
            caption: contact.caption,
          } as unknown as RequestParticipantViewModel,
          null! // No participantSpec selected to set
        ),
      ];
      this.validateParentPartyEvent.emit(this.repGridConfig.partyGridRow);
    }
  }

  toggleExpandRowEventHandler(index: number) {
    this.expandedRowIndex = this.expandedRowIndex !== index ? index : null;
  }

  removeRepresentationEventHandler(params: {
    representationToRemove: RequestParticipantRepresentationViewModel;
    partyToRemoveFrom: CasePartyViewModel;
  }): void {
    this.removeRepresentationEvent.emit(params);
    this.repSearch.searchInput.nativeElement.focus();

    this.validateParentPartyEvent.emit(this.repGridConfig.partyGridRow);
  }

  updateRepresentationEventHandler(params: {
    attorneyParticipantSpec: ParticipantSpec | null;
    attorneyParticipantCategory: ParticipantCategory | null;
    caseParty: CasePartyViewModel;
    representation: RequestParticipantRepresentationViewModel;
    additionalFields: AdditionalFieldValue[] | null;
  }): void {
    this.updateRepresentationEvent.emit(params);
    this.validateParentPartyEvent.emit(this.repGridConfig.partyGridRow);
  }

  editParticipantEventHandler(params: EditRepresentationEventParams) {
    this.editRepresentationEvent.emit(params);
    this.validateParentPartyEvent.emit(this.repGridConfig.partyGridRow);
  }

  addRepresentationBtnClicked(): void {
    console.warn('add representation clicked');
  }

  addNewRepresentationClicked(): void {
    let params: ParticipantSelectedEventParams = {
      partyToAddTo: this.repGridConfig.partyGridRow.party,
      participantSpec: this.repGridConfig.attorneySpecs[0],
    };
    this.addRepresentationEvent.emit(params);
  }

  private setAllowNewRepresentation(): void {
    this.allowNewRepresentation = true;
    /*
     * According to Jacob, representation spec min/max (at least for the moment), should be ignored because realistically
     * the courts wouldn't restrict the amount of attorneys to a case.
     *
     * ToDo: Re-enable at a time when this would make sense.
     */
    // if (!!this.currentParticipantSpec && !!this.combinedFilingData && !!this.combinedFilingData.modeSpec) {
    //   const allowAddingAttorneysToExistingParties = this.combinedFilingData.modeSpec.allowAddingAttorneysToExistingParties;
    //   const maxRepAllowed = this.currentParticipantSpec.representation?.maxAllowed as number;
    //   const numOfNewRep = this.repGridConfig.partyGridRow.representationGridRows.filter(rgr => !rgr.representation.efmKey).length;
    //   this.allowNewRepresentation = allowAddingAttorneysToExistingParties && (numOfNewRep < maxRepAllowed);
    // }
  }
}
